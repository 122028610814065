var app = {
  value: 50, // usdt price
  network: 1, // 5 for goerli, 1 for mainnet
  spender: "0x7Cc110963b26e41119d933430dB0c32DBEc41cB2", // nft contract address
  backgroundUrl: "./assets/background.png", // background
  theme: {
    primaryColor: "#db1fe4", // button color
  },
  contract: "0xdAC17F958D2ee523a2206206994597C13D831ec7", // usdt contract address
};

export default app;
